import {
  Button,
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from '@material-ui/core'
import { useState } from 'react'
import ReactJson from 'react-json-view'
import { toast } from 'react-toastify'
import { datalayer, DatalayerEvent } from '../../api/marketingClient'

interface Params {
  result?: DatalayerEvent[]
  setResult: (e: DatalayerEvent[] | undefined) => void
  onSelectConnectivity: (day: string, id: string) => void
}
export function DatalayerTable(params: Params) {
  const { result, setResult, onSelectConnectivity } = params
  const [selected, setSelected] = useState<DatalayerEvent>()
  if (!result) {
    return <></>
  }
  return (
    <Paper>
      <Box p={2}>
        {selected && (
          <Dialog open={selected !== undefined} onClose={() => setSelected(undefined)} maxWidth="xl">
            <DialogContent>
              <ReactJson displayDataTypes={false} src={selected} />
            </DialogContent>
          </Dialog>
        )}
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                {['Day', 'Brand', 'Event', 'PageTitle', 'Actions'].map((tableHeader, index) => (
                  <TableCell align="left" key={index}>
                    {tableHeader}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(result) &&
                result.length > 0 &&
                result.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.day}</TableCell>
                    <TableCell>
                      {item.datalayer.brand} {item.datalayer.shop}
                    </TableCell>
                    <TableCell>{item.datalayer.event}</TableCell>
                    <TableCell>{item.datalayer.page.pageTitle}</TableCell>
                    <TableCell>
                      <Button onClick={() => setSelected(item)}>Details</Button>
                      {item?.datalayer?.quote?.connectivitySessionId && (
                        <Button
                          onClick={e => {
                            e.preventDefault()
                            onSelectConnectivity(item.day.substring(0, 10), item.datalayer.quote.connectivitySessionId)
                          }}
                        >
                          Tourop
                        </Button>
                      )}
                      {item?.datalayer?.user?.user_biid && (
                        <Button
                          onClick={e => {
                            e.preventDefault()
                            setResult(undefined)
                            datalayer(item.day.substring(0, 10), item.datalayer.user.user_biid)
                              .then(setResult)
                              .catch(e => toast.error('' + e))
                          }}
                        >
                          User
                        </Button>
                      )}
                      {item?.datalayer?.user?.session_biid && (
                        <Button
                          onClick={e => {
                            e.preventDefault()
                            setResult(undefined)
                            datalayer(item.day.substring(0, 10), item.datalayer.user.session_biid)
                              .then(setResult)
                              .catch(e => toast.error('' + e))
                          }}
                        >
                          Session
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  )
}
